import React from 'react';

import Seo from '../components/Seo';
import { StaticImage } from 'gatsby-plugin-image';
import '../style/recording.scss';
import { RiTapeLine } from 'react-icons/ri';
import { StyledButton } from '../components/StyledButton';

const Recording = () => {
  return (
    <>
      <Seo title="Musik Produktion" />
      <div className="card">
        <div className=" flex recording">
          <div className="card-left">
            <h2>
              <RiTapeLine className="icon" />
              Produktion
            </h2>
          </div>
          <div className="card-right">
            <h1>Musik Produktion</h1>
            <p>
              {' '}
              Als zertifizierter Audio Engineer erstelle ich z.B. die Aufnahme
              eines Orchesters oder einer Band, das Hörspiel eines
              Schul-Ferienprojektes, eine individuelle Weihnachts CD /
              Geburtstagsgeschenk für Opa, Oma{' '}
            </p>
            <p>- alles ist möglich! -</p>
            <StaticImage
              className="recording-img"
              src="../images/recording.jpg"
              alt="Recording Bild"
              fit="cover"
              placeholder="blurred"
              loading="eager"
              aspectRatio={21 / 9}
            />
            <p>
              Große Produktionen für Firmen- und Messe Events waren genauso
              dabei wie das Demo einer Schülerband
            </p>

            <ul className="bullet">
              <li>Band Recordings Live Konzert oder im Studio/Proberaum</li>
              <li>Hörspiele/Hörbücher</li>
              <li>Dein Playback für z.B. Hochzeiten</li>
              <li>Hochzeitsüberraschungs CD deiner Lieblings Songs</li>
              <li>Dein Song als Geschenk</li>
              <li>Karaoke Junggesellenabschiede</li>
            </ul>

            <StyledButton
              label="Hol dir dein individuelles Angebot"
              to="/kontakt"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Recording;
